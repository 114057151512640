import { useDefaultStateBounds } from '@hooks/use-default-state-bounds';
import {
	type Match,
	useSearchByBoundingBox,
} from '@hooks/use-search-by-bounding-box';
import {
	APIProvider,
	AdvancedMarker,
	Map as ReactGoogleMap,
} from '@vis.gl/react-google-maps';
import { compose } from '@xo-union/react-css-modules';
import Icon from '@xo-union/tk-component-icons';
import { FullscreenModal } from '@xo-union/tk-component-modals';
import debounce from 'lodash/debounce';
import React, { type FC, useState } from 'react';
import { googleMapsAPIKey } from '../../../../../settings';
import { ClearFiltersButton } from './components/ClearFiltersButton';
import { MiniVendorCard } from './components/MiniVendorCard';
import { MapPin } from './components/map-pin';
import { useFilteredVendors } from './hooks/use-filtered-vendors';
import { useMapHandlers } from './hooks/use-map-handlers';
import { useTrackMapView } from './hooks/use-track-map-view';
import Styles from './styles.scss';

export const MIN_ZOOM = 6;
const MAP_ID = 'DEMO_MAP_ID';

export type PinStates = 'default' | 'visited' | 'selected';

type GoogleMapProps = {
	match: Match;
	searchLocation: string;
	setModalOpen: (value: boolean) => void;
};

export const GoogleMap: FC<GoogleMapProps> = ({
	match,
	searchLocation,
	setModalOpen,
}) => {
	const [pinStates, setPinStates] = useState<Record<string, PinStates>>({});
	const trackMapView = useTrackMapView();
	const { onTilesLoaded, trackDrag, trackZoom } = useMapHandlers(trackMapView);
	const handlePinClick = (vendorId: string) => {
		setShowCard(true);
		setSelectedVendor(foundVendor(vendorId));
		trackMapView('pin-clicked');
		setPinStates((prev) => ({
			...Object.keys(prev).reduce(
				(acc, key) => {
					acc[key] = 'visited';
					return acc;
				},
				{} as Record<string, PinStates>,
			),
			[vendorId]: 'selected',
		}));
	};
	const [once, setOnce] = useState(false);
	const [isCleared, setIsCleared] = useState(false);
	const { defaultBounds } = useDefaultStateBounds();
	const { searchByBoundingBox } = useSearchByBoundingBox(
		match,
		searchLocation,
		isCleared,
	);
	const [showCard, setShowCard] = useState(true);
	const [selectedVendor, setSelectedVendor] = useState<Vendor.Raw | undefined>(
		undefined,
	);
	const { filteredVendors, setFilteredVendors, setFilteredVendorsWrapper } =
		useFilteredVendors();

	const foundVendor = (id: string | undefined): Vendor.Raw | undefined => {
		return filteredVendors.find((vendor) => vendor.id === id);
	};

	const boundsChanged = debounce((e) => {
		if (!once) {
			setOnce(true);
			return;
		}
		const { bounds } = e.detail;
		const { north, east, south, west } = bounds;
		setShowCard(false);

		if (selectedVendor?.location?.address) {
			const { latitude, longitude } = selectedVendor.location.address;
			const isInBounds =
				latitude <= north &&
				latitude >= south &&
				longitude <= east &&
				longitude >= west;

			if (!isInBounds) {
				setPinStates((prev) => ({
					...prev,
					[selectedVendor.id]: 'visited',
				}));
			}
		}

		if (north && east && south && west) {
			searchByBoundingBox(bounds, setFilteredVendorsWrapper);
		}
	}, 500);

	const handleClose = () => {
		trackMapView('close-map-view-click');
		setModalOpen(false);
	};

	return (
		<FullscreenModal
			onClose={handleClose}
			data-testid="fullscreenModal"
			classes={compose({ header: Styles.header })}
		>
			<button
				type="button"
				aria-label="Close Modal"
				className={Styles.floatingCloseButton}
				onClick={handleClose}
			>
				<Icon name="close" size="md" />
			</button>

			<APIProvider apiKey={googleMapsAPIKey}>
				<ReactGoogleMap
					className={Styles.reactMap}
					mapId={MAP_ID}
					fullscreenControl={false}
					mapTypeControl={false}
					streetViewControl={false}
					zoomControl={false}
					gestureHandling={'greedy'}
					defaultBounds={defaultBounds}
					onBoundsChanged={boundsChanged}
					minZoom={MIN_ZOOM}
					onZoomChanged={trackZoom}
					onDragend={trackDrag}
					onTilesLoaded={onTilesLoaded}
				>
					<ClearFiltersButton
						isCleared={isCleared}
						setIsCleared={setIsCleared}
						setFilteredVendors={setFilteredVendors}
						trackMapView={trackMapView}
					/>
					{filteredVendors.map((vendor) => {
						return (
							<AdvancedMarker
								key={vendor.name}
								position={{
									lat: vendor.location.address.latitude,
									lng: vendor.location.address.longitude,
								}}
								title={vendor.name}
								onClick={() => handlePinClick(vendor.id)}
							>
								<MapPin
									vendorName={vendor.name}
									rating={vendor.reviewSummary?.overallRating}
									state={pinStates[vendor.id]}
								/>
							</AdvancedMarker>
						);
					})}
					{showCard && selectedVendor && (
						<MiniVendorCard
							vendor={selectedVendor}
							trackMapView={trackMapView}
						/>
					)}
				</ReactGoogleMap>
			</APIProvider>
		</FullscreenModal>
	);
};
