import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';
import type { Raw } from 'types/vendor';
import AnalyticsConstants from '../../../../../../constants/analytics';
import Vendor from '../../../../../decorators/vendor';
import VendorAnalytics from '../../../../../decorators/vendorAnalytics';

export type TrackType =
	| 'vendor-card-click'
	| 'clear-filters-click'
	| 'close-map-view-click'
	| 'pin-clicked'
	| 'zoom-in'
	| 'zoom-out'
	| 'drag-end';

export type TrackMapView = (
	trackType: TrackType,
	vendor?: Raw | undefined,
) => void;

export const SHARED_PROPS = {
	product: 'marketplace',
	sourcePage: 'category results',
	sourceContent: 'map_view',
};

export const trackPinClicked = () => {
	return {
		event: AnalyticsConstants.BUTTON_CLICKED,
		properties: {
			...SHARED_PROPS,
			action: 'select_pin',
		},
	};
};

export const trackVendorCardClick = (vendor?: Raw) => {
	if (!vendor) {
		return {
			event: '',
			properties: {},
		};
	}

	const decoratedVendor = new Vendor(vendor);
	const vendorAnalytics = new VendorAnalytics(decoratedVendor);
	return {
		event: AnalyticsConstants.CLICK_THROUGH_TO_VENDORS,
		properties: {
			...SHARED_PROPS,
			...vendorAnalytics.baseEventProps(),
		},
	};
};

export const trackClearFiltersClick = () => {
	return {
		event: AnalyticsConstants.BUTTON_CLICKED,
		properties: {
			...SHARED_PROPS,
			action: 'clear_filters',
		},
	};
};

export const trackCloseMapViewClick = () => {
	return {
		event: AnalyticsConstants.BUTTON_CLICKED,
		properties: {
			...SHARED_PROPS,
			action: 'close_map',
		},
	};
};

export const trackMapScroll = () => {
	return {
		event: AnalyticsConstants.BUTTON_CLICKED,
		properties: {
			...SHARED_PROPS,
			action: 'scroll',
		},
	};
};

export const trackMapZoomIn = () => {
	return {
		event: AnalyticsConstants.BUTTON_CLICKED,
		properties: {
			...SHARED_PROPS,
			action: 'zoom_in',
		},
	};
};

export const trackMapZoomOut = () => {
	return {
		event: AnalyticsConstants.BUTTON_CLICKED,
		properties: {
			...SHARED_PROPS,
			action: 'zoom_out',
		},
	};
};

export const useTrackMapView = () => {
	const { track } = useAnalyticsContext();

	return useCallback(
		(trackType: TrackType, vendor?: Raw) => {
			const trackFunctions = {
				'pin-clicked': trackPinClicked,
				'vendor-card-click': () => trackVendorCardClick(vendor),
				'clear-filters-click': trackClearFiltersClick,
				'close-map-view-click': trackCloseMapViewClick,
				'drag-end': trackMapScroll,
				'zoom-in': trackMapZoomIn,
				'zoom-out': trackMapZoomOut,
			};

			if (trackFunctions[trackType]) {
				return track({
					...trackFunctions[trackType](),
				});
			}
		},
		[track],
	);
};
