import Dropdown from '@components/shared/PillWithDropdown/Dropdown/components';
import MobileDropdown from '@components/shared/PillWithDropdown/Dropdown/containers/Mobile';
import BaseStyles from '@components/shared/PillWithDropdown/Dropdown/styles.scss';
import Pill from '@components/shared/PillWithDropdown/Pill';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { newSortPillAssignmentSelector } from '@redux/experiments/selectors/new-sort-pill';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { commitAppliedFilterPillChanges } from '@redux/search';
import { useDesktopMedia } from '@xo-union/tk-ui-breakpoints';
import React, { useCallback, useRef, useState } from 'react';
import { SortType } from 'types/search';
import { afterNextPaint } from '../../../../../../../helpers/after-next-paint';
import { updateSort } from '../../../../actions/search';
import useClickListenerOnPillDropdown from '../../hooks/useClickListenerOnPillDropdown';
import Styles from './styles.scss';

const options = [
	{ name: 'Featured', value: 'featured' },
	{ name: 'Recommended', value: 'recommended' },
	{ name: 'Distance', value: 'distance' },
	{ name: 'Ratings', value: 'ratings' },
];

const optionToSortType: Record<string, SortType> = {
	featured: 'featured',
	recommended: 'recommended',
	distance: 'distance',
	ratings: 'review-rating',
};

const sortTypeToOption: Record<SortType, string> = {
	featured: 'featured',
	recommended: 'recommended',
	distance: 'distance',
	'review-rating': 'ratings',
};

const SortPill = () => {
	const dispatch = useAppDispatch();
	const sortType = useAppSelector((state) => state.search.sort);
	const containerRef = useRef<HTMLDivElement>(null);
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState(
		sortTypeToOption[sortType],
	);
	const isDesktop = useDesktopMedia();
	const { track } = useAnalyticsContext();
	const newSortPillAssignment = useAppSelector((state) =>
		newSortPillAssignmentSelector(state),
	);

	const isInNewSortPillExperiment = newSortPillAssignment === 'new-sort-pill';
	const pillLabel = isInNewSortPillExperiment
		? 'Sort by'
		: `Sort by ${selectedOption}`;

	const onClick = useCallback(() => {
		afterNextPaint(() => {
			if (!dropdownOpen) {
				track({
					event: 'Button Clicked',
					properties: {
						sourcePage: 'category results',
						sourceContent: 'listings_sort',
						action: 'open',
					},
				});
			}

			const nextDropdownToggle = !dropdownOpen;
			setDropdownOpen(nextDropdownToggle);
		});
	}, [track, dropdownOpen]);

	const closeDropdown = () => {
		setDropdownOpen(false);
	};
	useClickListenerOnPillDropdown({
		closeDropdown,
		containerRef,
		dropdownOpen,
		isMobile: isDesktop.no,
	});

	const handleSelectSortType = useCallback(
		([newSortType]) => {
			afterNextPaint(() => {
				track({
					event: 'Button Clicked',
					properties: {
						sourcePage: 'category results',
						sourceContent: 'listings_sort',
						action: 'select_option',
						listings_sort: newSortType,
					},
				});

				setSelectedOption(newSortType);
				dispatch(updateSort(optionToSortType[newSortType]));
				dispatch(commitAppliedFilterPillChanges());
				setDropdownOpen(false);
			});
		},
		[dispatch],
	);

	const handleSetSelectedOption = ([newSortType]: SortType[]) => {
		setSelectedOption(newSortType);
	};

	return (
		<>
			<Pill label={pillLabel} onClick={onClick} selected={dropdownOpen} />
			{dropdownOpen && isDesktop.yes && (
				<div
					className={`${BaseStyles.dropdown} ${Styles.margin}`}
					ref={containerRef}
				>
					<Dropdown
						options={options}
						stagedOptions={[selectedOption]}
						setStagedOptions={handleSelectSortType}
						singleSelect
					/>
				</div>
			)}
			{dropdownOpen && !isDesktop.yes && (
				<MobileDropdown
					isModalOpen={dropdownOpen}
					modalHeader="Sort"
					options={options}
					onSave={handleSelectSortType}
					onClose={closeDropdown}
					stagedOptions={[selectedOption]}
					setStagedOptions={handleSetSelectedOption}
					isSortFilter
					singleSelect
				/>
			)}
		</>
	);
};

export default SortPill;
