import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { AppliedFilterPillProps } from '@typings/search';
import { Column } from '@xo-union/tk-component-grid';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as SearchActions from '../../../../../../redux/search/actions/actionCreators';
import { startingPriceNumberFormat } from '../../components/StartingPriceFilter/utils';
import { buildClosedCategoryFilters, trackFilterVendors } from '../../helpers';
import AppliedFiltersRow from './containers/AppliedFiltersRow';
import { calculateAppliedFiltersSize, windowWidth } from './helpers';
import Styles from './styles.scss';

interface StateProps {
	isMobile: boolean;
	filters: Search.AppliedFilterPillProps[];
	vendorCategoryCode: Category.CategoryCode;
	categoryName: string;
	location: Redux.Location;
	sizes: Search.Sizes;
}

interface DispatchProps {
	commitAppliedFilterPillChanges: () => Search.Actions.CommitAppliedFilterPillChanges;
	removeAppliedFilterPillOptions: (
		filters: Search.AppliedFilterPillProps[],
	) => Search.Actions.RemoveAppliedFilterPillOptions;
	setClearSidebarFlag: (flag: string) => Search.Actions.SetClearSidebarFlag;
}

export type Props = StateProps & DispatchProps;

type AppliedFilterRow = Search.AppliedFilterPillProps[];
const generateRows = (
	isMobile: boolean,
	sizes: Search.Sizes,
	appliedFilters: Search.AppliedFilterPillProps[],
): AppliedFilterRow[] => {
	const { header: headerWidth, search: searchWidth } = sizes;
	// Applied filters should not extend past the search container
	// Including "clear filters"
	const rows: AppliedFilterRow[] = [[]];
	// Mobile does not extend past the width of the page (minus 20px of padding on either side)
	// Desktop does not extend past where the search is in the header.
	const availableWidth = isMobile
		? windowWidth() - 40
		: headerWidth - searchWidth;
	let currentRow = 0;
	appliedFilters.forEach((filter, index) => {
		const isLastIndex = index === appliedFilters.length - 1;
		if (
			calculateAppliedFiltersSize(
				[...rows[currentRow], filter],
				isLastIndex,
				isMobile,
				currentRow,
			) < availableWidth
		) {
			rows[currentRow].push(filter);
		} else {
			currentRow += 1;
			rows[currentRow] = [filter];
		}
	});

	return rows;
};

// For truncation on mobile
const calculateHiddenFilters = (
	rows: AppliedFilterRow[],
	appliedFilters: Search.AppliedFilterPillProps[],
) => {
	if (rows.length < 2) {
		return 0;
	}

	return appliedFilters.length - (rows[0].length + rows[1].length);
};

const renderAppliedFilters = (
	appliedFilters: Search.AppliedFilterPillProps[],
	sizes: Search.Sizes,
	closeFilter: (filterLabel: string) => void,
	isMobile: boolean,
	expandFilters: boolean,
	setExpandFilters: (expandFiltersFlag: boolean) => void,
) => {
	const rows = generateRows(isMobile, sizes, appliedFilters);
	const numberOfHiddenFilters =
		expandFilters || !isMobile
			? 0
			: calculateHiddenFilters(rows, appliedFilters);

	return (
		<div className={Styles.appliedFiltersContainer}>
			{rows
				.map((row, index) => {
					const renderRow =
						appliedFilters.length > 0 &&
						(!isMobile || expandFilters || index < 2);
					const isLastRow =
						index === rows.length - 1 ||
						(isMobile && index === 1 && !expandFilters);
					if (renderRow) {
						return (
							<AppliedFiltersRow
								closeFilter={closeFilter}
								// eslint-disable-next-line react/no-array-index-key
								key={`applied-filter-row-${index}`}
								index={index}
								isLastRow={isLastRow}
								numberOfHiddenFilters={numberOfHiddenFilters}
								setExpandFilters={setExpandFilters}
								row={row}
							/>
						);
					}

					return null;
				})
				.filter((r) => r !== null)}
		</div>
	);
};

const AppliedFilters: FunctionComponent<Props> = ({
	filters,
	categoryName,
	commitAppliedFilterPillChanges,
	removeAppliedFilterPillOptions,
	isMobile,
	setClearSidebarFlag,
	sizes,
	location,
	vendorCategoryCode,
}) => {
	const { track } = useAnalyticsContext();
	const [expandFilters, setExpandFilters] = useState(!isMobile);
	const getStartingPriceDisplay = (
		minFilter: AppliedFilterPillProps | undefined,
		maxFilter: AppliedFilterPillProps | undefined,
	) => {
		if (minFilter && maxFilter) {
			return `${startingPriceNumberFormat.format(Number(minFilter.value))} - ${startingPriceNumberFormat.format(Number(maxFilter.value))}`;
		}
		if (minFilter) {
			return `${startingPriceNumberFormat.format(Number(minFilter.value))}+`;
		}
		if (maxFilter) {
			return `Up to ${startingPriceNumberFormat.format(Number(maxFilter.value))}`;
		}
		return '';
	};
	const displayableFilters = useMemo(() => {
		const filtersCopy = [...filters];
		const minFilter = filters.find((f) => f.categorySlug === 'minPrice');
		const maxFilter = filters.find((f) => f.categorySlug === 'maxPrice');
		if (minFilter || maxFilter) {
			filtersCopy.push({
				id: 'starting-price',
				name: getStartingPriceDisplay(minFilter, maxFilter),
				value: 'starting-price',
				categorySlug: 'starting-price',
			});
		}
		return filtersCopy.filter(
			(f) => f.categorySlug !== 'minPrice' && f.categorySlug !== 'maxPrice',
		);
	}, [filters]);
	useEffect(() => {
		if (isMobile && expandFilters) {
			setExpandFilters(false);
		}
	}, [filters.map((f) => f.id).join()]);
	const closeFilter = (filterLabel: string) => {
		const displayedFilter = displayableFilters.find(
			(f) => f.name === filterLabel,
		);
		const filtersToRemove: AppliedFilterPillProps[] = [];
		if (displayedFilter?.categorySlug === 'starting-price') {
			const minPriceFilter = filters.find((f) => f.categorySlug === 'minPrice');
			const maxPriceFilter = filters.find((f) => f.categorySlug === 'maxPrice');
			if (minPriceFilter) {
				filtersToRemove.push(minPriceFilter);
			}
			if (maxPriceFilter) {
				filtersToRemove.push(maxPriceFilter);
			}
		} else if (displayedFilter) {
			filtersToRemove.push(displayedFilter);
		}
		removeAppliedFilterPillOptions(filtersToRemove);
		commitAppliedFilterPillChanges();
		setClearSidebarFlag(filterLabel);
		trackFilterVendors({
			track,
			categoryCode: vendorCategoryCode,
			categoryFilters: buildClosedCategoryFilters({
				add: [],
				remove: filtersToRemove,
				commitChanges: true,
			}),
			categoryName,
			location,
			trackAction: 'remove applied filter',
		});
	};

	return (
		<Column xs="12">
			{renderAppliedFilters(
				displayableFilters,
				sizes,
				closeFilter,
				isMobile,
				expandFilters,
				setExpandFilters,
			)}
		</Column>
	);
};

export const mapStateToProps = (state: Redux.State): StateProps => ({
	filters: state.search.filterPills.applied,
	vendorCategoryCode: state.category.code,
	categoryName: state.category.plural.term,
	location: state.location,
	sizes: state.search.sizes,
	isMobile: state.viewport.isMobile,
});

export const mapDispatchToProps: DispatchProps = {
	commitAppliedFilterPillChanges: SearchActions.commitAppliedFilterPillChanges,
	removeAppliedFilterPillOptions: SearchActions.removeAppliedFilterPillOptions,
	setClearSidebarFlag: SearchActions.setClearSidebarFlag,
};

export default connect<StateProps, DispatchProps, {}>(
	mapStateToProps,
	mapDispatchToProps,
)(AppliedFilters);
