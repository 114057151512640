import { useMoreSpotlights } from '@hooks/useSpotlightExperiment';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import { Row } from '@xo-union/tk-component-grid';
import React, { Fragment, FC } from 'react';
import { connect } from 'react-redux';
import { Raw } from 'types/vendor';
import { getCurrentPage, selectShowBuzzBadges } from '../../../../utils';
import BuzzBadgeSection from '../BuzzBadgeSection';
import Result from '../Result';
import Styles from '../styles.scss';
import { getSectionIndex, shouldRenderBuzzBadges } from '../utils/buzzBadges';

type StateProps = ReturnType<typeof mapStateToProps>;

export type ResultsProps = StateProps;

const Results: FC<ResultsProps> = (props) => {
	const {
		category,
		currentPage,
		marketCode,
		maxResultColumns,
		showBuzzBadges,
		vendors,
	} = props;
	if (vendors.length === 0) {
		return null;
	}

	const [hasMoreSpotlights] = useMoreSpotlights({
		categoryCode: category.code,
		marketCode,
	});
	return (
		<Row vGutters classes={compose({ row: Styles.rowContainer })}>
			{vendors.map((vendor, index) => (
				<Fragment key={vendor.id}>
					<VendorResultColumn
						index={index}
						vendor={vendor}
						vendorCount={vendors.length}
					/>
					{shouldRenderBuzzBadges(
						showBuzzBadges,
						index,
						maxResultColumns,
						hasMoreSpotlights,
					) && (
						<BuzzBadgeSection
							categoryCode={category.code}
							currentPage={currentPage}
							hasMoreSpotlights={hasMoreSpotlights}
							marketCode={marketCode}
							sectionIndex={getSectionIndex(
								index,
								maxResultColumns,
								hasMoreSpotlights,
							)}
						/>
					)}
				</Fragment>
			))}
		</Row>
	);
};

interface VendorResultColumnProps {
	index: number;
	vendor: Raw;
	vendorCount: number;
}

function VendorResultColumn(props: VendorResultColumnProps) {
	const { index, vendor, vendorCount } = props;
	return (
		<Result
			impressionType="Directory: Main"
			index={index}
			vendor={vendor}
			vendorsLength={vendorCount}
		/>
	);
}

const mapStateToProps = (state: Redux.State) => {
	return {
		category: state.category,
		currentPage: getCurrentPage(state),
		marketCode: state.settings.marketCode as string,
		maxResultColumns: state.search.maxResultColumns,
		showBuzzBadges: selectShowBuzzBadges(state),
		vendors: state.search.vendors,
	};
};

export default connect<StateProps, {}, {}>(mapStateToProps)(Results);
