import { Body2, Subhead } from '@xo-union/tk-ui-typography';
import React, { type FunctionComponent } from 'react';
import Styles from './styles.scss';

interface BuzzBadgeHeaderProps {
	buzzBadgeIds: string[];
}

const BuzzBadgeHeader: FunctionComponent<BuzzBadgeHeaderProps> = (props) => {
	const { buzzBadgeIds } = props;

	if (buzzBadgeIds.length === 0) {
		return null;
	}

	return (
		<div className={Styles.buzzBadgesHeader}>
			<Body2 className={Styles.buzzBadgesLabel}>Promotion</Body2>
			<Subhead bold className={Styles.buzzBadgesTitle}>
				Couples are Buzzing
			</Subhead>
		</div>
	);
};

export default BuzzBadgeHeader;
