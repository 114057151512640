import { useShouldShowPricingFilter } from '@feature-flags/hooks/VendorsSearch/use-should-show-pricing-filter';
import {
	DEFAULT_ALL_RADIO_OPTION_ID,
	filtersNamesWithAllOption,
} from '@redux/search';
import React, { type FC } from 'react';
import { isPricingName } from '../../../../../../utils/validate';
import FilterPill from '../../components/FilterPill';
import SortPill from '../../components/SortPill';
import { StartingPriceFilterPill } from '../../components/StartingPriceFilter/starting-price-filter-pill';

export interface FilterPillCategoryProps {
	filterCategory: Search.FilterPillCategory;
}

const FilterPillCategory: FC<FilterPillCategoryProps> = (props) => {
	const { filterCategory } = props;
	const shouldShowPricingFilter = useShouldShowPricingFilter();
	const defaultProps = filtersNamesWithAllOption.includes(filterCategory)
		? { defaultOptions: [DEFAULT_ALL_RADIO_OPTION_ID] }
		: {};

	if (filterCategory === 'Sort') {
		return <SortPill />;
	}

	if (shouldShowPricingFilter && isPricingName(filterCategory)) {
		return <StartingPriceFilterPill />;
	}

	return <FilterPill filterCategory={filterCategory} {...defaultProps} />;
};

export default FilterPillCategory;
