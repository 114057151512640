import type { Match } from '@hooks/use-search-by-bounding-box';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Button } from '@xo-union/tk-component-buttons';
import React, { type FC, useCallback, useState } from 'react';
import AnalyticsEvents from '../../../../../constants/analytics';
import { GoogleMap } from './GoogleMap';
import Styles from './styles.scss';

interface MapViewButtonProps {
	match: Match;
	searchLocation: string;
}

export const MapViewButton: FC<MapViewButtonProps> = ({
	match,
	searchLocation,
}) => {
	const [modalOpen, setModalOpen] = useState(false);
	const { track } = useAnalyticsContext();
	const trackMapClicked = useCallback(() => {
		const properties = {
			product: 'marketplace',
			sourcePage: 'category results',
			sourceContent: 'map_cta',
			action: 'open',
		};

		track({
			event: AnalyticsEvents.BUTTON_CLICKED,
			properties,
		});
	}, [track]);
	const onClick = () => {
		trackMapClicked();
		setModalOpen(true);
	};

	return (
		<>
			<Button
				color="secondary-alternative"
				size="sm"
				iconName="map"
				labelDirection="right"
				onClick={onClick}
				className={`${Styles.pillContainer} ${Styles.hasRightLabel}`}
			>
				Map
			</Button>
			{modalOpen && (
				<GoogleMap
					match={match}
					searchLocation={searchLocation}
					setModalOpen={setModalOpen}
				/>
			)}
		</>
	);
};
