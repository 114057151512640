import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';

export type TrackingActions =
	| 'save selected filters'
	| 'clear selected filters';

export interface Payload<T extends TrackingActions> {
	min: number;
	max: number;
	action: T;
}

const generatePayload = ({ action, min, max }: Payload<TrackingActions>) => {
	return {
		event: 'Filter Vendors',
		properties: {
			action,
			starting_price_min: min,
			starting_price_max: max,
		},
	};
};

export const useTrackSlider = () => {
	const { track } = useAnalyticsContext();

	return useCallback(
		({ min, max, action }: Payload<TrackingActions>) => {
			const payload = generatePayload({ min, max, action });
			return track(payload);
		},
		[track],
	);
};
