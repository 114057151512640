import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { modifySelected } from '@redux/search';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { useEffect, useMemo, useRef } from 'react';
import FilterStyles from '../SidebarOptions/styles.scss';
import { StartingPriceFilter } from './starting-price-filter';
import { useStartingPriceFilter } from './use-starting-price-filter';

export const StartingPriceFilterSidebarOption = () => {
	const dispatch = useAppDispatch();
	const selected = useAppSelector(
		(state) => state.search.filterPills.sidebar.selected,
	);
	const startingPriceFilterProps = useStartingPriceFilter();
	const { minInput, maxInput } = startingPriceFilterProps;
	const startingPriceSelectedId = useMemo(() => {
		return (
			(minInput || maxInput) &&
			`starting_price-${minInput || 'min'}-${maxInput || 'max'}`
		);
	}, [minInput, maxInput]);
	const prevStartingPriceSelectedIdRef = useRef(startingPriceSelectedId);

	useEffect(() => {
		if (prevStartingPriceSelectedIdRef.current === startingPriceSelectedId)
			return;
		const selectedNoMinMax = selected.filter(
			(f) => !f.includes('starting_price'),
		);
		if (startingPriceSelectedId) {
			dispatch(modifySelected([...selectedNoMinMax, startingPriceSelectedId]));
		} else {
			dispatch(modifySelected(selectedNoMinMax));
		}
	}, [startingPriceSelectedId, dispatch, selected]);

	useEffect(() => {
		prevStartingPriceSelectedIdRef.current = startingPriceSelectedId;
	}, [startingPriceSelectedId]);

	return (
		<div className={FilterStyles.filterWrapper}>
			<Body1 className={FilterStyles.filterLabel} bold>
				Starting Price
			</Body1>
			<div className={FilterStyles.filterSeparator}>
				<StartingPriceFilter {...startingPriceFilterProps} />
			</div>
		</div>
	);
};
