import SaveButton from '@components/shared/PillWithDropdown/Dropdown/components/SaveButton';
import BaseStyles from '@components/shared/PillWithDropdown/Dropdown/styles.scss';
import DropdownStyles from '@components/shared/PillWithDropdown/Dropdown/styles.scss';
import Pill from '@components/shared/PillWithDropdown/Pill';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
	addAppliedFilterPillOptions,
	commitAppliedFilterPillChanges,
	removeAppliedFilterPillOptions,
} from '@redux/search';
import { useDesktopMedia } from '@xo-union/tk-ui-breakpoints';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { useAtomValue } from 'jotai';
import React, { useCallback, useRef, useState } from 'react';
import { afterNextPaint } from '../../../../../../../helpers/after-next-paint';
import { useTrackSlider } from '../../hooks/use-track-slider';
import useClickListenerOnPillDropdown from '../../hooks/useClickListenerOnPillDropdown';
import { isDirtyMinMaxAtom } from './atoms';
import { StartingPriceFilter } from './starting-price-filter';
import Styles from './styles.scss';
import { useStartingPriceFilter } from './use-starting-price-filter';
import { getAppliedStartingPriceFilterOptions } from './utils';
interface StartingPriceFilterDropdownProps {
	closeDropdown: () => void;
}

const StartingPriceFilterDropdown = ({
	closeDropdown,
}: StartingPriceFilterDropdownProps) => {
	const {
		clear,
		startingMin,
		startingMax,
		step,
		minInput,
		maxInput,
		minVal,
		maxVal,
		setMaxInput,
		setMaxVal,
		setMinInput,
		setMinVal,
	} = useStartingPriceFilter();
	const dispatch = useAppDispatch();
	const savedFilters = useAppSelector(
		(state) => state.search.filterPills.applied,
	);
	const isDirtyMinMax = useAtomValue(isDirtyMinMaxAtom);
	const trackSlider = useTrackSlider();

	const onClickSave = () => {
		afterNextPaint(() => {
			const appliedFilterOptions = getAppliedStartingPriceFilterOptions(
				minInput,
				maxInput,
			);

			const filtersToRemove = savedFilters.filter(
				(filter) =>
					filter.categorySlug === 'minPrice' ||
					filter.categorySlug === 'maxPrice' ||
					filter.value === 'inexpensive' ||
					filter.value === 'affordable' ||
					filter.value === 'luxury',
			);
			dispatch(removeAppliedFilterPillOptions(filtersToRemove));
			dispatch(addAppliedFilterPillOptions(appliedFilterOptions));
			dispatch(commitAppliedFilterPillChanges());
			trackSlider({
				action: 'save selected filters',
				min: minVal,
				max: maxVal,
			});
			closeDropdown();
		});
	};

	const onClickClear = () => {
		trackSlider({ action: 'clear selected filters', min: minVal, max: maxVal });
		clear();
	};

	return (
		<>
			<div className={Styles.pillDropdown}>
				<StartingPriceFilter
					startingMax={startingMax}
					startingMin={startingMin}
					minVal={minVal}
					maxVal={maxVal}
					setMinVal={setMinVal}
					setMaxVal={setMaxVal}
					minInput={minInput}
					maxInput={maxInput}
					setMinInput={setMinInput}
					setMaxInput={setMaxInput}
					step={step}
				/>
			</div>
			<div className={Styles.border}>
				<div className={DropdownStyles.ctaSection}>
					<DisplayButton
						className={DropdownStyles.clear}
						type="reset"
						onClick={onClickClear}
					>
						Clear
					</DisplayButton>
					<SaveButton
						disabled={!isDirtyMinMax}
						stagedOptions={[`${minInput} - ${maxInput}`]}
						onSave={onClickSave}
					>
						Save
					</SaveButton>
				</div>
			</div>
		</>
	);
};

export const StartingPriceFilterPill = () => {
	const isDesktop = useDesktopMedia();
	const { resetStagedMinMax } = useStartingPriceFilter();
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
	const isFilterApplied = useAppSelector((state) =>
		state.search.filterPills.applied.some(
			(filter) =>
				filter.categorySlug === 'minPrice' ||
				filter.categorySlug === 'maxPrice' ||
				filter.value === 'inexpensive' ||
				filter.value === 'affordable' ||
				filter.value === 'luxury',
		),
	);
	const containerRef = useRef<HTMLDivElement>(null);
	const onClick = useCallback(() => {
		if (dropdownOpen) {
			closeDropdownAndReset();
		} else {
			setDropdownOpen(true);
		}
	}, [dropdownOpen]);
	const closeDropdown = () => {
		setDropdownOpen(false);
	};
	const closeDropdownAndReset = () => {
		setDropdownOpen(false);
		resetStagedMinMax();
	};

	useClickListenerOnPillDropdown({
		closeDropdown: closeDropdownAndReset,
		containerRef,
		dropdownOpen,
		isMobile: isDesktop.no,
	});
	return (
		<div ref={containerRef} className={Styles.pill}>
			<Pill
				label="Starting Price"
				onClick={onClick}
				selected={dropdownOpen || isFilterApplied}
			/>
			{dropdownOpen && isDesktop.yes && (
				<div className={`${BaseStyles.dropdown}`}>
					<StartingPriceFilterDropdown closeDropdown={closeDropdown} />
				</div>
			)}
		</div>
	);
};
