import { GooglePublisherTag } from '@components/google-publisher-tag';
import React, {
	type Dispatch,
	type FC,
	type SetStateAction,
	useState,
} from 'react';
import { adSlotEnv } from '../../../../../../../../../settings';
import { renderStyles } from './utils';

interface BuzzBadgeProps {
	categoryCode: string;
	currentPage: number;
	index: number;
	marketCode?: string;
	offset: number;
	setBuzzBadgeIds: Dispatch<SetStateAction<string[]>>;
}

const BuzzBadge: FC<BuzzBadgeProps> = (props) => {
	const {
		categoryCode,
		currentPage,
		index,
		marketCode,
		offset,
		setBuzzBadgeIds,
	} = props;
	const [isAdEmpty, setIsAdEmpty] = useState(true);

	return (
		<div
			className={renderStyles(isAdEmpty)}
			key={`${currentPage}-${offset}-${index}`}
		>
			<GooglePublisherTag
				destroyAdOnCleanup
				id={`buzz-badge-${offset + index}`}
				setBuzzBadgeIds={setBuzzBadgeIds}
				setIsAdEmpty={setIsAdEmpty}
				size={[296, 76]}
				targeting={{
					cat: categoryCode,
					city: marketCode,
					pos: 'local_buzz_badge',
					env: adSlotEnv,
				}}
			/>
		</div>
	);
};

export default BuzzBadge;
