import useAnalyticsContext from '@hooks/useAnalyticsContext';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { afterNextPaint } from '../../../../../../../../../helpers/after-next-paint';
import * as SearchActions from '../../../../../../../../redux/search/actions/actionCreators';
import { useStartingPriceFilter } from '../../../../components/StartingPriceFilter/use-starting-price-filter';
import { trackFilterVendors } from '../../../../helpers';
import Styles from '../../styles.scss';

interface StateProps {
	categoryName: string;
	location: Redux.Location;
	vendorCategoryCode: Category.CategoryCode;
}

interface DispatchProps {
	clearAppliedFilterPillOptions: () => Search.Actions.ClearAppliedFilterPillOptions;
	setClearSidebarFlag: (flag: string) => Search.Actions.SetClearSidebarFlag;
}

export type Props = StateProps & DispatchProps;

const ClearFiltersLink: FunctionComponent<Props> = ({
	categoryName,
	clearAppliedFilterPillOptions,
	location,
	setClearSidebarFlag,
	vendorCategoryCode,
}) => {
	const { track } = useAnalyticsContext();
	const { clear } = useStartingPriceFilter();

	const clearFilters = () => {
		afterNextPaint(() => {
			trackFilterVendors({
				track,
				categoryCode: vendorCategoryCode,
				categoryFilters: {},
				categoryName,
				location,
				trackAction: 'reset filters',
			});
			clearAppliedFilterPillOptions();
			setClearSidebarFlag('ALL');
			clear();
		});
	};

	return (
		<button
			className={Styles.clearFilters}
			onClick={clearFilters}
			type="button"
		>
			Clear All
		</button>
	);
};

export const mapStateToProps = (state: Redux.State): StateProps => ({
	categoryName: state.category.plural.term,
	location: state.location,
	vendorCategoryCode: state.category.code,
});

export const mapDispatchToProps: DispatchProps = {
	clearAppliedFilterPillOptions: SearchActions.clearAppliedFilterPillOptions,
	setClearSidebarFlag: SearchActions.setClearSidebarFlag,
};
export default connect<StateProps, DispatchProps, {}>(
	mapStateToProps,
	mapDispatchToProps,
)(ClearFiltersLink);
