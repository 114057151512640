import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
	clearAppliedFilterPillOptions,
	modifyApplied,
	setClearSidebarFlag,
} from '@redux/search/actions';
import { Button } from '@xo-union/tk-component-buttons';
import React, { type Dispatch, type FC, type SetStateAction } from 'react';
import { useClearedSearch } from '../hooks/use-cleared-search';
import type { TrackMapView } from '../hooks/use-track-map-view';
import Styles from './clear-filters-button.scss';

interface ClearFiltersButtonProps {
	isCleared: boolean;
	setFilteredVendors: Dispatch<SetStateAction<Vendor.Raw[]>>;
	setIsCleared: Dispatch<SetStateAction<boolean>>;
	trackMapView: TrackMapView;
}

const ClearFiltersButton: FC<ClearFiltersButtonProps> = (props) => {
	const { isCleared, setFilteredVendors, setIsCleared, trackMapView } = props;
	const clearedSearch = useClearedSearch(setFilteredVendors);
	const dispatch = useAppDispatch();

	const filterCount = useAppSelector(
		(state) => state.search.filterPills.applied.length,
	);

	const isZeroFilters = isCleared || filterCount === 0;

	if (isZeroFilters) {
		return null;
	}

	const clearFilters = () => {
		trackMapView('clear-filters-click');
		setIsCleared(true);
		clearedSearch();
		dispatch(modifyApplied([]));
		dispatch(clearAppliedFilterPillOptions());
		dispatch(setClearSidebarFlag('ALL'));
	};

	return (
		<Button
			color="secondary-alternative"
			size="sm"
			className={Styles.btn}
			onClick={clearFilters}
		>
			Clear filters {`(${filterCount})`}
		</Button>
	);
};

export { ClearFiltersButton };
