import { getStagedFilteredResultsCtaText } from '@components/shared/PillWithDropdown/Dropdown/containers/Mobile/utils';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { DEFAULT_ALL_RADIO_OPTION_ID } from '@redux/search';
import * as SearchActions from '@redux/search/actions';
import { Button } from '@xo-union/tk-component-buttons';
import {
	Pane,
	PrimaryCTA,
	SecondaryCTA,
	Sidebar as SidebarUnion,
} from '@xo-union/tk-ui-sidebar';
import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { afterNextPaint } from '../../../../../../../helpers/after-next-paint';
import * as SeoActions from '../../../../actions/seo';
import { saveSidebarOptions, selectedFiltersToKeep } from '../../utils/helpers';
import SidebarOptions from '../SidebarOptions';
import { useStartingPriceFilter } from '../StartingPriceFilter/use-starting-price-filter';
import { getSelectedStartingPriceFilterOption } from '../StartingPriceFilter/utils';
import MobileHeader from './MobileHeader';
import Styles from './styles.scss';
export interface OwnProps {
	categoryFilters: Search.FilterDetails[];
	flattenSidebarOptions: string[];
}
interface StateProps {
	applied: Search.AppliedFilterPillProps[];
	appliedFilters: Search.AppliedFilterPillProps[];
	category: Redux.Category;
	location: Redux.Location;
	isMobile: boolean;
	isSidebarOpen: boolean;
	selected: string[];
	stagedResultsCount: number;
}

interface DispatchProps {
	addAppliedFilterPillOptions: (
		filters: Search.AppliedFilterPillProps[],
	) => Search.Actions.AddAppliedFilterPillOptions;
	commitAppliedFilterPillChanges: () => Search.Actions.CommitAppliedFilterPillChanges;
	fetchResultsCount: (filters: string[]) => void;
	modifyApplied: (
		applied: Search.AppliedFilterPillProps[],
	) => Search.Actions.ModifyApplied;
	modifySelected: (selected: string[]) => Search.Actions.ModifySelected;
	removeAppliedFilterPillOptions: (
		filters: Search.AppliedFilterPillProps[],
	) => Search.Actions.RemoveAppliedFilterPillOptions;
	resetStagedResultsCount: () => Search.Actions.ResetStagedResultsCount;
	setIsSidebarOpen: (bool: boolean) => Search.Actions.SetIsSidebarOpen;
	updateSeoFilterValue: (seoFilter?: string) => Seo.UpdateSeoFilterValueAction;
}

export type Props = OwnProps & StateProps & DispatchProps;

const Sidebar: FC<Props> = ({
	addAppliedFilterPillOptions,
	applied,
	appliedFilters,
	category,
	categoryFilters,
	commitAppliedFilterPillChanges,
	fetchResultsCount,
	flattenSidebarOptions,
	isMobile,
	isSidebarOpen,
	location,
	modifyApplied,
	modifySelected,
	removeAppliedFilterPillOptions,
	resetStagedResultsCount,
	selected,
	setIsSidebarOpen,
	stagedResultsCount,
	updateSeoFilterValue,
}) => {
	const { track } = useAnalyticsContext();
	const { resetStagedMinMax } = useStartingPriceFilter();

	useEffect(() => {
		if (!isMobile) {
			return;
		}
		const allAppliedFilters = applied.map((filter) => filter.id);
		const appliedFiltersOutsideOfSidebar = allAppliedFilters.filter(
			(filter) => !appliedFilters.map((x) => x.id).includes(filter),
		);

		const searchCountFilters = [
			category.id,
			...appliedFiltersOutsideOfSidebar,
			...selected,
		];

		fetchResultsCount(searchCountFilters);
	}, [selected.join()]);

	const onClose = (): void => {
		afterNextPaint(() => {
			const keep = selectedFiltersToKeep(selected, appliedFilters);
			modifySelected(keep);
			setIsSidebarOpen(false);
			resetStagedMinMax();
			resetStagedResultsCount();
		});
	};

	const primary = () => {
		afterNextPaint(() => {
			saveSidebarOptions(
				{
					addAppliedFilterPillOptions,
					commitAppliedFilterPillChanges,
					modifyApplied,
					removeAppliedFilterPillOptions,
					setIsSidebarOpen,
					track,
					updateSeoFilterValue,
				},
				{
					applied,
					appliedFilters,
					category,
					categoryFilters,
					flattenSidebarOptions,
					location,
					selected,
				},
			);
		});
	};

	const selectedNonDefaultOptions = selected.filter(
		(o) => o !== DEFAULT_ALL_RADIO_OPTION_ID,
	);
	const noSidebarChanges = useMemo(() => {
		const selectedStartingPrice =
			getSelectedStartingPriceFilterOption(appliedFilters);
		const appliedFiltersById = appliedFilters
			.filter((f) => !f.id.includes('starting-price'))
			.map((f) => f.id);
		if (selectedStartingPrice) {
			appliedFiltersById.push(selectedStartingPrice);
		}
		return (
			appliedFiltersById.length === selectedNonDefaultOptions.length &&
			appliedFiltersById.every((f) => selectedNonDefaultOptions.includes(f))
		);
	}, [appliedFilters, selectedNonDefaultOptions]);

	const ctaText = useMemo(() => {
		return getStagedFilteredResultsCtaText(stagedResultsCount);
	}, [stagedResultsCount]);

	const renderPrimaryCTA = () => {
		if (isMobile) {
			return (
				<Button
					block="sm"
					color="primary"
					size="md"
					onClick={primary}
					disabled={stagedResultsCount === 0 || noSidebarChanges}
				>
					{ctaText}
				</Button>
			);
		}

		return (
			<PrimaryCTA
				disabled={noSidebarChanges}
				onClick={primary}
				style={
					appliedFilters.length > 0 || selected.length > 0
						? {}
						: { opacity: 0.5 }
				}
			>
				Save
			</PrimaryCTA>
		);
	};

	const secondary = () => {
		const keep = selectedFiltersToKeep(selected, appliedFilters);
		modifySelected(keep);
		setIsSidebarOpen(false);
	};

	const renderSecondaryCTA = () =>
		!isMobile && <SecondaryCTA onClick={secondary}>Cancel</SecondaryCTA>;

	const renderSidebar = () => (
		<SidebarUnion isOpen={isSidebarOpen} onClose={onClose}>
			<div className={Styles.sidebarWrapper}>
				<Pane
					header={
						isMobile
							? ((<MobileHeader />) as unknown as string)
							: 'More Filters'
					}
					renderPrimaryCTA={renderPrimaryCTA}
					renderSecondaryCTA={renderSecondaryCTA}
				>
					<SidebarOptions
						categoryFilters={categoryFilters}
						isMobile={isMobile}
					/>
				</Pane>
			</div>
		</SidebarUnion>
	);

	const memoizedSidebar = useMemo(
		() => renderSidebar(),
		[isSidebarOpen, stagedResultsCount, applied, appliedFilters, selected],
	);

	return memoizedSidebar;
};

export const mapStateToProps = (state: Redux.State): StateProps => ({
	applied: state.search.filterPills.applied,
	appliedFilters: state.search.filterPills.sidebar.applied,
	category: state.category,
	location: state.location,
	isMobile: state.viewport.isMobile,
	isSidebarOpen: state.search.filterPills.sidebar.isSidebarOpen,
	selected: state.search.filterPills.sidebar.selected,
	stagedResultsCount: state.search.filterPills.stagedResultsCount,
});

export const mapDispatchToProps: DispatchProps = {
	addAppliedFilterPillOptions: SearchActions.addAppliedFilterPillOptions,
	commitAppliedFilterPillChanges: SearchActions.commitAppliedFilterPillChanges,
	fetchResultsCount: SearchActions.fetchResultsCount,
	modifyApplied: SearchActions.modifyApplied,
	modifySelected: SearchActions.modifySelected,
	removeAppliedFilterPillOptions: SearchActions.removeAppliedFilterPillOptions,
	resetStagedResultsCount: SearchActions.resetStagedResultsCount,
	setIsSidebarOpen: SearchActions.setIsSidebarOpen,
	updateSeoFilterValue: SeoActions.updateSeoFilterValue,
};

export default connect<StateProps, DispatchProps, OwnProps>(
	mapStateToProps,
	mapDispatchToProps,
)(Sidebar);
