import { useShouldShowPricingFilter } from '@feature-flags/hooks/VendorsSearch/use-should-show-pricing-filter';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { FC, useState, useEffect } from 'react';
import { isPricingName } from '../../../../../../utils/validate';
import { labels } from '../../../utils';
import MultiOption from '../MultiOption';
import SingleOption from '../SingleOption';
import { StartingPriceFilterSidebarOption } from '../StartingPriceFilter/starting-price-filter-sidebar-option';
import Styles from './styles.scss';

export const calcHeight = (len: number): number => {
	const isEven = len % 2 === 0;
	const half = Math.floor(len / 2);
	return isEven ? half * 50 : (half + 1) * 50;
};

export const removeOutdoorOptions = (
	options: Search.FilterDetails[],
): Search.FilterDetails[] => {
	const filtersCopy: Search.FilterDetails[] = [...options];
	for (const filter of filtersCopy) {
		const { filterOptions, name } = filter;

		if (name !== 'Outdoor Space') {
			const filteredOptions = filterOptions.filter(
				(option) =>
					option.name !== 'Outdoor Event Space' &&
					option.name !== 'Covered Outdoors Space',
			);
			filtersCopy[filtersCopy.indexOf(filter)].filterOptions = filteredOptions;
		}
	}

	return filtersCopy;
};

export interface SidebarOptionsProps {
	categoryFilters: Search.FilterDetails[];
	isMobile: boolean;
}

const SidebarOptions: FC<SidebarOptionsProps> = ({
	categoryFilters,
	isMobile,
}) => {
	const [filters, setFilters] = useState<Search.FilterDetails[]>([]);
	const shouldShowPricingFilter = useShouldShowPricingFilter();

	useEffect(() => {
		setFilters(removeOutdoorOptions(categoryFilters));
	}, [categoryFilters.length]);

	return (
		<div className="sidebarFiltersWrapper">
			{filters.length > 0 &&
				filters.map((filter: Search.FilterDetails) => {
					const filterName =
						labels[filter.name as keyof typeof labels] || filter.name;

					const isSingleSelect = filter.isSingleSelect;
					const splitOptionStyle = isMobile
						? {}
						: { height: calcHeight(filter.filterOptions.length) };

					if (isPricingName(filterName) && shouldShowPricingFilter) {
						return <StartingPriceFilterSidebarOption key={filterName} />;
					}

					return (
						<div className={Styles.filterWrapper} key={filterName}>
							<Body1 className={Styles.filterLabel} bold>
								{filterName}
							</Body1>
							<div className={Styles.splitOptions} style={splitOptionStyle}>
								{isSingleSelect ? (
									<SingleOption filter={filter} />
								) : (
									<MultiOption filter={filter} />
								)}
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default SidebarOptions;
