import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { PVRecWidget } from '../../../../containers/widget';
import algorithms from '../../../../containers/widget/constants/algorithms';
import Styles from './styles.scss';

export type RightRailProps = ReturnType<typeof mapStateToProps>;

const RightRail: FC<RightRailProps> = (props) => {
	const { location, marketCode } = props;

	return (
		<div className={Styles.rightRailSticky}>
			<div className={Styles.rightRailColumn}>
				<PVRecWidget
					algorithmType={algorithms.PVREC_ANON}
					cardType="sm"
					location={location}
					marketCode={marketCode}
					className={Styles.rightRailWidget}
				/>
			</div>
			<div className={Styles.rightRailBottom} />
		</div>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	location: state.location,
	marketCode: state.settings.marketCode,
});

export default connect(mapStateToProps)(RightRail);
