const BUZZ_BADGES_AFTER_COUNTS = [6, 12];
const BUZZ_BADGES_AFTER_COUNTS_FOUR_COL = [8, 16];
const BUZZ_BADGES_AFTER_COUNTS_FOR_SPOTLIGHT_EXPERIMENT_THREE_COL = [9, 15];

const buzzBadgeAfterCounts = (
	maxResultColumns: number,
	hasMoreSpotlights: boolean,
): number[] => {
	if (hasMoreSpotlights) {
		return BUZZ_BADGES_AFTER_COUNTS_FOR_SPOTLIGHT_EXPERIMENT_THREE_COL;
	}

	return maxResultColumns === 4
		? BUZZ_BADGES_AFTER_COUNTS_FOUR_COL
		: BUZZ_BADGES_AFTER_COUNTS;
};

const getBuzzBadgeOffset = (
	sectionIndex: number,
	maxResultColumns: number,
	hasMoreSpotlights: boolean,
): number => {
	return sectionIndex === 0
		? 0
		: buzzBadgeAfterCounts(maxResultColumns, hasMoreSpotlights)[
				sectionIndex - 1
			];
};

const getBuzzBadgeCount = (
	sectionIndex: number,
	maxResultColumns: number,
	hasMoreSpotlights: boolean,
): number => {
	if (hasMoreSpotlights) {
		// Although we're using more spotlights here, we can rely on these fewer spotlight counts to display the correct number of badges,
		// rather than defining another static variable.
		// Also true for four-up experiment. We want the same number of rendered buzz badges.
		return BUZZ_BADGES_AFTER_COUNTS[sectionIndex];
	}

	return sectionIndex > -1
		? buzzBadgeAfterCounts(maxResultColumns, hasMoreSpotlights)[sectionIndex]
		: 0;
};

const getBuzzBadgeConfig = (
	sectionIndex: number,
	getMaxResultColumns: () => number,
	hasMoreSpotlights: boolean,
) => {
	const count = getBuzzBadgeCount(
		sectionIndex,
		getMaxResultColumns(),
		hasMoreSpotlights,
	);
	const offset = getBuzzBadgeOffset(
		sectionIndex,
		getMaxResultColumns(),
		hasMoreSpotlights,
	);

	return {
		count,
		offset,
	};
};

// Using the index of the previously rendered Vendor Result, we can determine the Buzz Badge section index.
const getSectionIndex = (
	index: number,
	maxResultColumns = 3,
	hasMoreSpotlights: boolean,
): number =>
	buzzBadgeAfterCounts(maxResultColumns, hasMoreSpotlights).findIndex(
		(i) => i === index + 1,
	);

const shouldRenderBuzzBadges = (
	showBuzzBadges: boolean,
	index: number,
	maxResultColumns: number,
	hasMoreSpotlights: boolean,
): boolean => {
	const buzzBadgeAfterCountsValue = buzzBadgeAfterCounts(
		maxResultColumns,
		hasMoreSpotlights,
	);
	return showBuzzBadges && buzzBadgeAfterCountsValue.includes(index + 1);
};

export {
	buzzBadgeAfterCounts,
	getBuzzBadgeConfig,
	getBuzzBadgeCount,
	getBuzzBadgeOffset,
	getSectionIndex,
	shouldRenderBuzzBadges,
};
