import { Column, Row } from '@xo-union/tk-component-grid';
import React, { Fragment, FC } from 'react';
import PaginationContainer from '../Pagination';
import SearchResults from '../SearchResults/components';

const View: FC = () => {
	return (
		<Fragment>
			<Row>
				<Column xs="12">
					<SearchResults />
				</Column>
			</Row>
			<Row>
				<Column xs="12">
					<PaginationContainer />
				</Column>
			</Row>
		</Fragment>
	);
};

export default View;
