import { Field } from '@xo-union/tk-component-fields';
import React, { useMemo, useCallback } from 'react';
import { RangeSlider } from './range-slider';
import Styles from './styles.scss';
import { startingPriceNumberFormat } from './utils';

const sanitizeInput = (value: string) => value.replace(/^0+|\D/g, '');

interface StartingPriceFilterProps {
	startingMin: number;
	startingMax: number;
	minVal: number;
	maxVal: number;
	setMinVal: (value: number) => void;
	setMaxVal: (value: number) => void;
	minInput: string;
	maxInput: string;
	setMinInput: (value: string) => void;
	setMaxInput: (value: string) => void;
	step: number;
}

export const StartingPriceFilter = (props: StartingPriceFilterProps) => {
	const {
		startingMax,
		startingMin,
		minInput,
		maxInput,
		minVal,
		maxVal,
		setMaxInput,
		setMaxVal,
		setMinInput,
		setMinVal,
		step,
	} = props;

	const minInputDisplay = useMemo(() => {
		const minNumber = Number(minInput);
		return Number.isNaN(minNumber) || !minNumber
			? ''
			: startingPriceNumberFormat.format(minNumber);
	}, [minInput]);
	const maxInputDisplay = useMemo(() => {
		const maxNumber = Number(maxInput);
		return Number.isNaN(maxNumber) || !maxNumber
			? ''
			: startingPriceNumberFormat.format(maxNumber);
	}, [maxInput]);

	const onChangeMinInput = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			setMinInput(sanitizeInput(e.target.value)),
		[setMinInput],
	);

	const onChangeMaxInput = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			setMaxInput(sanitizeInput(e.target.value)),
		[setMaxInput],
	);

	const setMinInputValue = useCallback(
		(value: number) => {
			const shouldSetToEmpty =
				Number.isNaN(value) || value <= startingMin || value >= maxVal;
			setMinInput(shouldSetToEmpty ? '' : value.toString());
		},
		[startingMin, maxVal, setMinInput],
	);

	const setMaxInputValue = useCallback(
		(value: number) => {
			const shouldSetToEmpty =
				Number.isNaN(value) || value >= startingMax || value <= minVal;
			setMaxInput(shouldSetToEmpty ? '' : value.toString());
		},
		[startingMax, minVal, setMaxInput],
	);

	const setMinSliderValue = useCallback(
		(value: number) => {
			const shouldSetToStarting =
				Number.isNaN(value) || value <= startingMin || value >= maxVal;
			setMinVal(shouldSetToStarting ? startingMin : value);
		},
		[startingMin, maxVal, setMinVal],
	);

	const setMaxSliderValue = useCallback(
		(value: number) => {
			const shouldSetToStarting =
				Number.isNaN(value) || value >= startingMax || value <= minVal;
			setMaxVal(shouldSetToStarting ? startingMax : value);
		},
		[startingMax, minVal, setMaxVal],
	);

	const setMin = useCallback(
		(value: number) => {
			setMinSliderValue(value);
			setMinInputValue(value);
		},
		[setMinInputValue, setMinSliderValue],
	);

	const setMax = useCallback(
		(value: number) => {
			setMaxSliderValue(value);
			setMaxInputValue(value);
		},
		[setMaxInputValue, setMaxSliderValue],
	);

	const onBlurMinInput = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			setMin(Number(sanitizeInput(e.target.value)));
		},
		[setMin],
	);

	const onBlurMaxInput = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			setMax(Number(sanitizeInput(e.target.value)));
		},
		[setMax],
	);

	const onKeyDownMinInput = useCallback(
		(e) => {
			if (e.key === 'Enter') {
				setMin(Number(sanitizeInput(e.target.value)));
			}
		},
		[setMin],
	);

	const onKeyDownMaxInput = useCallback(
		(e) => {
			if (e.key === 'Enter') {
				setMax(Number(sanitizeInput(e.target.value)));
			}
		},
		[setMax],
	);

	return (
		<div className={Styles.startingPriceContainer}>
			<div className={Styles.startingPriceMinMax}>
				<Field
					name="Minimum"
					value={minInputDisplay}
					onChange={onChangeMinInput}
					onBlur={onBlurMinInput}
					onKeyDown={onKeyDownMinInput}
				/>
				<span className={Styles.startingPriceDash}>-</span>
				<Field
					name="Maximum"
					value={maxInputDisplay}
					onChange={onChangeMaxInput}
					onBlur={onBlurMaxInput}
					onKeyDown={onKeyDownMaxInput}
				/>
			</div>
			<RangeSlider
				startingMin={startingMin}
				startingMax={startingMax}
				minVal={minVal}
				maxVal={maxVal}
				setMinVal={setMin}
				setMaxVal={setMax}
				step={step}
			/>
			<div className={Styles.rangeValuesContainer}>
				<div>{startingPriceNumberFormat.format(startingMin)}</div>
				<div>{`${startingPriceNumberFormat.format(startingMax)}+`}</div>
			</div>
		</div>
	);
};
