import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
	clearAtom,
	maxInputAtom,
	maxValAtom,
	minInputAtom,
	minValAtom,
	resetStagedMinMaxAtom,
	startingPriceFilterConfigAtom,
} from './atoms';

export const useStartingPriceFilter = () => {
	const [startingMin, startingMax, step] = useAtomValue(
		startingPriceFilterConfigAtom,
	);
	const [minVal, setMinVal] = useAtom(minValAtom);
	const [maxVal, setMaxVal] = useAtom(maxValAtom);
	const [minInput, setMinInput] = useAtom(minInputAtom);
	const [maxInput, setMaxInput] = useAtom(maxInputAtom);
	const resetStagedMinMax = useSetAtom(resetStagedMinMaxAtom);
	const clear = useSetAtom(clearAtom);

	return {
		clear,
		startingMin,
		startingMax,
		minVal,
		maxVal,
		setMinVal,
		setMaxVal,
		minInput,
		maxInput,
		setMinInput,
		setMaxInput,
		step,
		resetStagedMinMax,
	};
};
