import classNames from 'classnames';
import React, { useEffect, useRef, useCallback } from 'react';
import Styles from './styles.scss';

interface RangeSliderProps {
	step: number;
	startingMin: number;
	startingMax: number;
	minVal: number;
	maxVal: number;
	setMinVal: (value: number) => void;
	setMaxVal: (value: number) => void;
}

export const RangeSlider = ({
	startingMin,
	startingMax,
	minVal,
	maxVal,
	setMinVal,
	setMaxVal,
	step,
}: RangeSliderProps) => {
	const minValRef = useRef<HTMLInputElement | null>(null);
	const maxValRef = useRef<HTMLInputElement | null>(null);
	const range = useRef<HTMLDivElement | null>(null);

	const getPercent = useCallback(
		(value: number) =>
			Math.round(((value + startingMin) / (startingMax - startingMin)) * 100),
		[startingMin, startingMax],
	);
	// Set width of the range to decrease from the left side
	useEffect(() => {
		if (maxValRef.current) {
			const minPercent = getPercent(minVal);
			const maxPercent = getPercent(+maxValRef.current.value);

			if (range.current) {
				range.current.style.left = `${minPercent}%`;
				range.current.style.width = `${maxPercent - minPercent}%`;
			}
		}
	}, [minVal, getPercent]);

	// Set width of the range to decrease from the right side
	useEffect(() => {
		if (minValRef.current) {
			const minPercent = getPercent(+minValRef.current.value);
			const maxPercent = getPercent(maxVal);

			if (range.current) {
				range.current.style.width = `${maxPercent - minPercent}%`;
			}
		}
	}, [maxVal, getPercent]);

	const onChangeFromRange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(event.target.value);
		const inRangeValue = value >= maxVal ? maxVal - step : value;
		setMinVal(inRangeValue);
	};

	const onChangeToRange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(event.target.value);
		const inRangeValue = value <= minVal ? minVal + step : value;
		setMaxVal(inRangeValue);
	};

	return (
		<div className={Styles.rangeContainer}>
			<input
				data-testid="min-range-slider"
				type="range"
				ref={minValRef}
				min={startingMin}
				max={startingMax}
				value={minVal}
				step={step}
				className={classNames(Styles.thumb, Styles.thumbMin)}
				onChange={onChangeFromRange}
				tabIndex={-1}
			/>
			<input
				data-testid="max-range-slider"
				type="range"
				ref={maxValRef}
				min={startingMin}
				max={startingMax}
				value={maxVal}
				step={step}
				className={Styles.thumb}
				onChange={onChangeToRange}
				tabIndex={-1}
			/>
			<div className={Styles.slider}>
				<div className={Styles.sliderTrack} />
				<div ref={range} className={Styles.sliderRange} />
			</div>
		</div>
	);
};
