import { compose } from '@xo-union/react-css-modules';
import { Column, type ColumnProps, Row } from '@xo-union/tk-component-grid';
import { Bone, Skeleton } from '@xo-union/tk-ui-skeleton';
import React, { useContext, useMemo, type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import WeddingsContext from '../../../../contexts/WeddingsContext/WeddingsContext';
import Styles from './styles.scss';

const getColumnSizes = (maxResultColumns: number): ColumnProps => {
	if (maxResultColumns === 4) {
		return { xs: '12', md: '6', lg: '4', xxl: '3' };
	}

	return { xs: '12', md: '6', xxl: '4' };
};

export type LoadingProps = {
	cardCount?: number;
	overrideColumnSize?: ColumnProps;
};

const Loading: FC<LoadingProps> = ({ cardCount = 12, overrideColumnSize }) => {
	const { getMaxResultColumns } = useContext(WeddingsContext);
	const columnSizes =
		overrideColumnSize ?? getColumnSizes(getMaxResultColumns());

	const cards = useMemo(() => {
		return Array.from({ length: cardCount }).map(() => (
			<SkeletonCard
				columnSizes={columnSizes}
				key={`loading-skeleton-${uuidv4()}`}
			/>
		));
	}, [cardCount, columnSizes]);

	return (
		<Row hGutters vGutters classes={compose({ row: Styles.rowContainer })}>
			{cards}
		</Row>
	);
};

type SkeletonCardProps = {
	columnSizes: ColumnProps;
};

const SkeletonCard: FC<SkeletonCardProps> = ({ columnSizes }) => (
	<Column {...columnSizes}>
		<Skeleton aria-label="Loading...">
			<Bone className={Styles.boneImage} />
			<Bone className={Styles.boneTextSmall} />
			<Bone className={Styles.boneTextNormal} />
			<Bone className={Styles.boneButton} />
		</Skeleton>
	</Column>
);

export default Loading;
